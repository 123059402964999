<template>
<div>
    <v-container class="container--fluid grid-list-md text-center" style="margin-bottom: 50px">
        <h1>Payconnect Dashboard</h1>
        <pie-chart v-if="loaded && pieChartData" :chartdata="pieChartData" :options="options" />
        <v-row class="ma-1">
            <v-col cols="12" md="6">
                <line-chart v-if="loaded && transactionCountData" :chart-data="transactionCountData" :options="options" />
            </v-col>
            <v-col cols="12" md="6">
                <line-chart v-if="loaded && transactionAmountData" :chart-data="transactionAmountData" :options="options" />
            </v-col>
        </v-row>
        <v-row class="ma-1">
            <v-col cols="12" md="6">
                <horizontal-bar-chart v-if="loaded && barChartDataCount" :chart-data="barChartDataCount" :options="options" />
            </v-col>
            <v-col cols="12" md="6">
                <horizontal-bar-chart v-if="loaded && barChartDataAmount" :chart-data="barChartDataAmount" :options="options" />
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import LineChart from '@/views/components/LineChart.vue';
import HorizontalBarChart from '@/views/components/HorizontalBarChart.vue';
import PieChart from '@/views/components/PieChart.vue';
import SwitchAPI from '@/api/SwitchAPI'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Dashboard',
    components: {
        LineChart,
        PieChart,
        HorizontalBarChart
    },
    computed: {
        ...mapGetters([
            'attributes'
        ])
    },
    data: () => ({
        loaded: false,
        chartdata: null,
        transactionAmountData: null,
        transactionCountData: null,
        pieChartData: null,
        barChartDataAmount: null,
        barChartDataCount: null,
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    }),
    async mounted() {
        this.loaded = false
        await this.queryChartData()
        this.loaded = true
    },
    methods: {
        isAdmin() {
            const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            console.log(attributes)
            const permissionRoles = attributes ? attributes.role ?? [] : []
            if (permissionRoles.includes('admin')) return true
            return false
        },
        getMerchantId() {
            const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            const merchants = attributes ? attributes.merchant ?? [] : []
            const merchantString = merchants ? merchants.length > 0 ? merchants[0] : '' : ''
            if (merchantString !== '') {
                const merchant = JSON.parse(merchantString)
                const merchantId = merchant.merchantId
                return merchantId
            }
            return merchantString
        },
        getMap(list, value) {
            var map = []
            for (var i = 0; i < list.length; i++) {
                if (list[i][value] === undefined) {
                    map.push(0)
                } else {
                    map.push(list[i][value]);
                }
            }
            return map
        },
        async queryChartData() {
            try {
                const limit = 7 /*7 days of transaction*/
                var transactionList = undefined
                var totals = "dashboardTotals"

                if (this.isAdmin()) {
                    console.log("Admin dashboard - status")
                    const transactionStatusList = await SwitchAPI.getTransactionsTotalPerType()
                    if (transactionStatusList.error) {
                        console.log(transactionStatusList.error)
                    } else {
                        const transactionTotalsPerType = transactionStatusList.transactionTotalsPerType
                        transactionTotalsPerType
                        this.pieChartData = {
                            labels: ['EXPIRED', 'PAID', 'PENDING', 'POSTED'],
                            datasets: [{
                                backgroundColor: ["#41B883", "#E46651", "#00D8FF", '#5466cf'],
                                data: [
                                    transactionTotalsPerType.EXPIRED.percentage,
                                    transactionTotalsPerType.PAID.percentage,
                                    transactionTotalsPerType.PENDING.percentage,
                                    transactionTotalsPerType.POSTED.percentage,
                                ]
                            }]
                        }
                    }
                }

                if (this.isAdmin()) {
                    console.log("Admin Dashboard")
                    transactionList = await SwitchAPI.getTransactionsTotal(limit)
                } else {
                    console.log("Merchant Dashboard")
                    transactionList = await SwitchAPI.getMerchantTransactionsTotal(limit, 0, this.getMerchantId())
                    totals = "merchantTotals"
                }
                if (transactionList.error) {
                    //error getting data
                    console.log(transactionList.error)
                } else {
                    const listOfDataName = [...new Set(transactionList[totals].map(it => it.dataName))].reverse();
                    //const listOfTransactionAmount = [...new Set(transactionList[totals].map(it => it.transactionAmount))].reverse();
                    const listOfTransactionAmount = this.getMap(transactionList[totals], 'transactionAmount').reverse()
                    //const listOfTransactionAmountAvg = [...new Set(transactionList[totals].map(it => it.amountAverage))].reverse();
                    const listOfTransactionAmountAvg = this.getMap(transactionList[totals], 'amountAverage').reverse()
                    //const listOfTransactionCount = [...new Set(transactionList[totals].map(it => it.transactionCount))].reverse();
                    const listOfTransactionCount = this.getMap(transactionList[totals], 'transactionCount').reverse()
                    //const listOfTransactionCountAvg = [...new Set(transactionList[totals].map(it => it.countAverage))].reverse();
                    const listOfTransactionCountAvg = this.getMap(transactionList[totals], 'countAverage').reverse()
                    const amountDataSets = [{
                        label: 'Transaction Value',
                        backgroundColor: '#f87979',
                        data: listOfTransactionAmount
                    }]
                    const countDataSets = [{
                        label: 'Transaction Count',
                        backgroundColor: '#f87979',
                        data: listOfTransactionCount
                    }]
                    if (this.isAdmin()) {
                        amountDataSets.push({
                            label: 'Average',
                            backgroundColor: '#5380a9',
                            data: listOfTransactionAmountAvg
                        })
                        countDataSets.push({
                            label: 'Average',
                            backgroundColor: '#5380a9',
                            data: listOfTransactionCountAvg
                        })
                    }
                    this.transactionAmountData = {
                        labels: listOfDataName,
                        datasets: amountDataSets
                    }
                    this.transactionCountData = {
                        labels: listOfDataName,
                        datasets: countDataSets
                    }
                }

                if (this.isAdmin()) {
                    console.log("Admin dashboard - leaderboard")
                    const transactionLeaderboardList = await SwitchAPI.getTransactionsLeaderboard()
                    if (transactionLeaderboardList.error) {
                        console.log(transactionLeaderboardList.error)
                    } else {
                        const leaderboard = transactionLeaderboardList.merchantLeaderboard
                        const leaderBoardAmount = this.sortBy(leaderboard, 'transactionAmount', 'des')
                        const leaderBoardCount = this.sortBy(leaderboard, 'transactionCount', 'des')
                        const listOfDataNameAmount = [...new Set(leaderBoardAmount.map(it => it.name))];
                        const listOfDataNameCount = [...new Set(leaderBoardCount.map(it => it.name))];
                        const listOfDataAmount = this.getMap(leaderBoardAmount, 'transactionAmount')
                        const listOfDataCount = this.getMap(leaderBoardCount, 'transactionCount')
                        console.log(listOfDataAmount, listOfDataCount)
                        this.barChartDataAmount = {
                            labels: listOfDataNameAmount,
                            datasets: [{
                                label: "Transaction Value",
                                backgroundColor: '#41B883',
                                data: listOfDataAmount
                            }]
                        }
                        this.barChartDataCount = {
                            labels: listOfDataNameCount,
                            datasets: [{
                                label: "Transaction Count",
                                backgroundColor: '#41B883',
                                data: listOfDataCount
                            }]
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        sortBy(array, field, type = 'asc') {
            if (type === 'asc') {
                const sorted = array.slice(0).sort(function (a, b) {
                    return (a[field] > b[field]) ? 1 : (a[field] < b[field]) ? -1 : 0;
                });
                return sorted
            } else {
                const sorted = array.slice(0).sort(function (a, b) {
                    return (a[field] < b[field]) ? 1 : (a[field] > b[field]) ? -1 : 0;
                });
                return sorted
            }
        }
    }
};
</script>
